<template>
    <div>
        <vc-date-picker
            v-model="range"
            mode="date"
            is-range
            :masks="masks"
        >
        <template v-slot="{ inputValue, inputEvents, isDragging }">
            <CRow>
                <CCol lg="6">
                    <CInput
                        :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                        :value="inputValue.start"
                        v-on="inputEvents.start"
                    >
                        <template #prepend-content>
                            <CIcon name="cil-calendar"/>
                        </template>
                    </CInput>
                </CCol>
                <CCol lg="6">
                    <CInput
                        :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                        :value="inputValue.end"
                        v-on="inputEvents.end"
                    >
                        <template #prepend-content>
                            <CIcon name="cil-calendar-check"/>
                        </template>
                    </CInput>
                </CCol>
            </CRow>
            </template>
        </vc-date-picker>
    </div>
</template>
<script>
export default {
    name: 'CDateRange',
    data() {
        return {
            strt_key: 0,
            end_key: 0,
            range: {
                start: null,
                end: null,
            },
            masks: {
                input: 'YYYY-MMMM-DD',
            },
        }
    },
    created() {
        // this.setDateRange();
    },
    beforeMount () {
        // this.setDateRange();
    },  
    methods: {
        /**
         * This dynamically change the date range of this components.
         * @param object.start Date JavaScript format
         * @param object.end Date JavaScript format
         * @default range, Today - 1 Month
         * @returns Data Range; Start Date and End Date 
         */
        setDateRange: function (object=null) {
            if(object && object.start) {
                this.range.start = object.start
            } else {
                let today = new Date();
                let minus1_month = today.setMonth(today.getMonth() - 1); // Today - 1 Month
                this.range.start = new Date(minus1_month)
            }

            if(object && object.end) {
                    this.range.end = object.end;
            } else {
                this.range.end = new Date(); // Today
            }
        }
    }
}           
</script>